export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    perfil_id: [1, 2, 5, 6, 7, 10, 15, 11, 21, 22, 999],
  },
  {
    title: 'Cadastros',
    icon: 'FileTextIcon',
    perfil_id: [2, 999],
    children: [
      {
        title: 'Usuários',
        route: 'list-users',
        perfil_id: [2, 999],
      },
      {
        title: 'Perfis de Usuários',
        route: 'list-perfil-usuario',
        perfil_id: [2, 999],
      },
      {
        title: 'Tipos de Avaliação Clínica',
        route: 'list-avaliacao-clinica',
        perfil_id: [2, 999],
      },
      {
        title: 'Tipos de Acompanhante',
        route: 'list-tipo-acompanhante',
        perfil_id: [2, 999],
      },
      {
        title: 'Tipos de Transporte',
        route: 'list-tipo-transporte',
        perfil_id: [2, 999],
      },
      {
        title: 'Tipos de Auxilio Locomoção',
        route: 'list-tipo-auxilio-locomocao',
        perfil_id: [2, 999],
      },
      {
        title: 'Tipos de Endereços',
        route: 'list-tipo-domicilio',
        perfil_id: [2, 999],
      },
      {
        title: 'Tipos de Unidades de Saúde',
        route: 'list-tipo-unidade-saude',
        perfil_id: [2, 999],
      },
      {
        title: 'Tipos de Veículos',
        route: 'list-tipo-veiculo',
        perfil_id: [2],
      },
      {
        title: 'Tipos de Cuidados de Produtos',
        route: 'list-tipo-cuidado-produtos',
        perfil_id: [2, 999],
      },
      // {
      //   title: 'Tipos de Estabelecimentos',
      //   route: 'list-tipo-estabelecimento',
      //   perfil_id: [2],
      // },
      {
        title: 'Tipos de Unidades de Medidas',
        route: 'list-tipo-unidade-medida',
        perfil_id: [2, 999],
      },
      {
        title: 'Motivos de Transporte',
        route: 'list-motivo-transporte',
        perfil_id: [2, 999],
      },
      {
        title: 'Natureza de Transporte',
        route: 'list-natureza-transporte',
        perfil_id: [2, 999],
      },
      {
        title: 'Situação do Transporte',
        route: 'list-situacao-transporte',
        perfil_id: [2, 999],
      },
      // {
      //   title: 'Estabelecimentos',
      //   route: 'list-estabelecimento',
      //   perfil_id: [2],
      // },
      {
        title: 'CIDs',
        route: 'list-cids',
        perfil_id: [2, 999],
      },
      {
        title: 'CBOs',
        route: 'list-cbos',
        perfil_id: [2, 999],
      },
      {
        title: 'Veículos',
        route: 'list-veiculo',
        perfil_id: [2, 999],
      },
      {
        title: 'Pacientes',
        route: 'list-paciente',
        perfil_id: [2, 999],
      },
      {
        title: 'Produtos',
        route: 'list-produtos',
        perfil_id: [2, 999],
      },
      {
        title: 'Categorias de Produtos',
        route: 'list-categoria-produto',
        perfil_id: [2, 999],
      },
      {
        title: 'UF',
        route: 'list-ufs',
        perfil_id: [2, 999],
      },
      {
        title: 'Municipios',
        route: 'list-municipios',
        perfil_id: [2, 999],
      },
      {
        title: 'Unidades de Saúde',
        route: 'list-unidade-saude',
        perfil_id: [2, 999],
      },
      {
        title: 'Cancelamentos',
        route: 'list-cancelamentos',
        perfil_id: [2, 999],
      },
      {
        title: 'Classificação',
        route: 'list-classificacoes',
        perfil_id: [2, 999],
      },
      {
        title: 'Equipes',
        route: 'list-equipes',
        perfil_id: [2, 999],
      },
      {
        title: 'Tipos de Profissionais',
        route: 'list-tipo-profissional',
        perfil_id: [2, 999],
      },
      {
        title: 'Tripulações',
        route: 'list-tripulacaos',
        perfil_id: [2, 999],
      },
      {
        title: 'Tripulantes',
        route: 'list-tripulantes',
        perfil_id: [2, 999],
      },
      {
        title: 'Posições Veículos',
        route: 'list-posicao-veiculo',
        perfil_id: [2, 999],
      },
      {
        title: 'Grupos Checklists',
        route: 'list-groupchecklists',
        perfil_id: [2, 999],
      },
      {
        title: 'Itens Checklists',
        route: 'list-itemchecklists',
        perfil_id: [2, 999],
      },
      {
        title: 'Tipos de Desfechos',
        route: 'list-tipo_desfechos',
        perfil_id: [2, 999],
      },
      {
        title: 'Regioes',
        route: 'list-regioes',
        perfil_id: [2, 999],
      },
      // {
      //   title: 'Respostas Checklists',
      //   route: 'list-respostachecklists',
      //   perfil_id: [2],
      // },
    ],
  },
  {
    title: 'Solicitações',
    route: 'list-transporte',
    icon: 'HeartIcon',
    perfil_id: [1, 2, 5, 6, 7, 15, 11, 21, 22, 999],
  },
  {
    title: 'Aprovações',
    route: 'list-aprovacoes',
    icon: 'CheckSquareIcon',
    perfil_id: [2, 6, 7, 21, 11, 999],
  },
  {
    title: 'Programações',
    route: 'list-programacoes',
    icon: 'CalendarIcon',
    perfil_id: [1, 2, 5, 6, 7, 11, 21, 999],
  },

  {
    title: 'Finalizados e Cancelados',
    route: 'list-cancelados_finalizados',
    icon: 'XIcon',
    perfil_id: [1, 2, 5, 6, 7, 11, 21, 999],
  },
  {
    title: 'Eventos Sem Desfechos',
    route: 'list-eventos_desfechos',
    icon: 'ListIcon',
    perfil_id: [2, 6, 7, 999],
  },
  {
    title: 'Calendário',
    route: 'calendario',
    icon: 'CalendarIcon',
    perfil_id: [1, 2, 5, 6, 7, 11, 21, 999],
  },
  {
    title: 'Configurações',
    route: 'list-configuracoes',
    icon: 'SettingsIcon',
    perfil_id: [2, 999],
  },
  {
    title: 'Logs',
    route: 'list-logs_genericos',
    icon: 'ClipboardIcon',
    perfil_id: [2, 999],
  },
  // {
  //   title: 'Atendimentos',
  //   route: 'list-frota',
  //   icon: 'GridIcon',
  //   perfil_id: [1, 2, 5, 6, 7, 11, 21],
  // },
  {
    title: 'Frota',
    route: 'list-frota-new',
    icon: 'GridIcon',
    perfil_id: [1, 2, 5, 6, 7, 11, 21, 999],
  },
  {
    title: 'Gerenciar J4',
    route: 'list-gerenciar-j4',
    icon: 'SettingsIcon',
    perfil_id: [1, 2, 6, 7, 11, 21, 999],
  },
  {
    title: 'Atendimentos Condutor',
    route: 'list-condutor-index',
    icon: 'GridIcon',
    perfil_id: [20, 999],
  },
  {
    title: 'Checklist',
    route: 'condutor-checklist',
    icon: 'GridIcon',
    perfil_id: [20, 999],
  },
  {
    title: 'Checklists',
    route: 'list-checklists',
    icon: 'LinkIcon',
    perfil_id: [2, 7, 11, 999],
  },
  {
    title: 'Equipes Embarcadas',
    route: 'list-equipes_embarcadas',
    icon: 'UsersIcon',
    perfil_id: [2, 7, 11, 999],
  },
  {
    title: 'RIHs Vinculadas',
    route: 'list-rihs',
    icon: 'InfoIcon',
    perfil_id: [2, 21, 999],
  },
  {
    title: 'Testes de Performance',
    route: 'list-teste-performance',
    icon: 'AlignLeftIcon',
    perfil_id: [2, 999],
  },
]
